export const localeCodes = ["en","fr","es","it","de"]

export const localeMessages = {
}

export const additionalMessages = Object({"en":[],"fr":[],"es":[],"it":[],"de":[],})

export const resolveNuxtI18nOptions = async (context) => {
  const nuxtI18nOptions = Object({})
  const vueI18nOptionsLoader = async (context) => Object({"legacy":false,"locale":"en","fallbackLocale":"en","messages": Object({"en":{
  "general": {
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
    "jumpTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jump to:"])}
  },
  "contentCard": {
    "live": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Live"])},
    "watch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watch"])},
    "listen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listen"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
  },
  "globalHeader": {
    "flyWithUsCTA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])}
  },
  "floatingBar": {
    "live": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Live"])},
    "watch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watch"])}
  },
  "footer": {
    "catchphrase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The future of space travel has arrived"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
    "gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gallery"])},
    "merch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merch"])},
    "Instagram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instagram"])},
    "Twitter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twitter"])},
    "Youtube": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Youtube"])},
    "Facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook"])},
    "TikTok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TikTok"])},
    "Linkedin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linkedin"])}
  },
  "videoPlayer": {
    "viewInline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View video inline"])},
    "viewFullscreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View video fullscreen"])},
    "play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play"])},
    "pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pause"])},
    "live": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Live"])},
    "watchingNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watching now"])}
  },
  "audioPlayer": {
    "play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play audio"])},
    "pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pause audio"])}
  },
  "hotspotButton": {
    "viewHotspot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View hotspot content"])}
  },
  "newsList": {
    "eyebrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore The Latest"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News"])}
  },
  "newsDetail": {
    "relatedEyebrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stay up to date"])}
  },
  "contact": {
    "secondaryHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])}
  },
  "loader": {
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turning the impossible into the inevitable..."])}
  },
  "menu": {
    "fleetEyebrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technology"])},
    "fleetTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spacecraft<br />Fleet"])},
    "spaceportEyebrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take Off & Landing"])},
    "spaceportTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spaceport<br />America"])},
    "flightEyebrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Journey"])},
    "flightTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spaceflight<br />Experience"])},
    "About": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
    "News": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News"])},
    "Contact Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "Gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gallery"])},
    "FAQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
    "Company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
    "Galactic Unite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galactic Unite"])},
    "ESG Impact Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG Impact Report"])},
    "Merch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merch"])},
    "Work With Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work With Us"])},
    "More For": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More For"])},
    "Researchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Researchers"])},
    "Investors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investors"])},
    "Press": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Press"])},
    "Privacy Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "Terms of Use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms & Conditions"])},
    "Cookie Notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookie Notice"])},
    "ADA Statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADA Statement"])}
  },
  "form": {
    "headline1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up to<br />hear more"])},
    "headline2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What are you interested in?"])},
    "headline3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Just a few more details"])},
    "headline4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About your research"])},
    "headline5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About your research"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn about the Virgin Galactic experience and future availability of spaceflight ticket sales."])},
    "toc1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I accept the Virgin Galactic"])},
    "toc2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "toc3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and"])},
    "toc4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms & Conditions"])},
    "privacy1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By ticking this box, I have reviewed the"])},
    "privacy2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "privacy3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and I agree to receive phone calls, including automated phone calls, text messages and emails from Virgin Galactic and its affiliates concerning their Services."])},
    "privacy4": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize(["I understand that I may unsubscribe anytime by selecting Unsubscribe when a call or text is received, also available on the website or by Contacting Us or by contacting us at updates", _linked("", undefined, _type)])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
    "submitting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submitting..."])},
    "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish"])},
    "thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks"])},
    "interest1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virgin Galactic Updates"])},
    "interest2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Future Spaceflight Ticket Sales"])},
    "interest3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Research On A Spaceflight"])},
    "interest4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investor Updates"])},
    "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not enter any confidential or privileged information."])},
    "fields": {
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
      "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthday"])},
      "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
      "recentJob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most Recent Job"])},
      "researchOrganization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Research Organization"])},
      "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationality"])},
      "postalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal Code"])},
      "flightAvailability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When do you want to book?"])},
      "payload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Research type"])},
      "researchDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Describe your research"])},
      "organizationLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
      "organizationName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization name"])},
      "organizationProjectRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project role"])},
      "researchField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Research Field"])},
      "spaceExperience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have you conducted research in space before?"])}
    },
    "errors": {
      "firstNameRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What's your first name?"])},
      "firstNameMinimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your first name must be longer than 1 character"])},
      "lastNameRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What's your last name?"])},
      "lastNameMinimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your last name must be longer than 1 character"])},
      "emailInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This email address is not valid"])},
      "emailRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What's your email address?"])},
      "genderRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What's your gender?"])},
      "birthdayRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What's your birthday?"])},
      "birthdayMinimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid birthday"])},
      "birthdayMaximum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must be at least 18 years old"])},
      "postalCodeRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What's your postal code?"])},
      "nationalityRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What's your nationality?"])},
      "flightAvailabilityRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When do you want to book?"])},
      "phoneInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid phone number"])},
      "phoneMinimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your phone number must be longer than 5 characters"])},
      "phoneRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What's your phone number?"])},
      "organizationPhoneInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid organization phone number"])},
      "organizationPhoneMinimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The phone number must be longer than 5 characters"])},
      "organizationPhoneRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What's your organization phone number?"])},
      "organizationProjectRoleMinimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The project role must be longer than 1 character"])},
      "organizationProjectRoleRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What's your role on the project?"])},
      "termsAccept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must accept the terms and conditions"])},
      "privacyAccept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must accept the privacy policy"])},
      "researchDescriptionMinimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your description must be at least 10 characters"])},
      "researchDescriptionRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please describe your research"])},
      "researchOrganizationMinimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your organization name must be longer than 1 character"])},
      "researchOrganizationRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What's your research organization?"])},
      "payloadRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What are you interested in?"])},
      "researchFieldRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What's your research field?"])},
      "spaceExperienceRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What's your space experience?"])}
    },
    "genderOptions": {
      "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
      "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
      "non-binary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-binary"])},
      "Prefer not to answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prefer not to answer"])}
    },
    "payloadOptions": {
      "autonomous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autonomous"])},
      "human-tended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Human-Tended"])},
      "uncertain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uncertain"])}
    },
    "flightAvailabilityOptions": {
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5 Years"])},
      "Ready Now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready Now"])},
      "1-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-2 Years"])},
      "uncertain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uncertain"])}
    },
    "spaceExperienceOptions": {
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])}
    },
    "recentJobOptions": {
      "Aerospace or Aviation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aerospace or Aviation"])},
      "Arts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arts"])},
      "Education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education"])},
      "Engineering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engineering"])},
      "Entrepreneur or Business Owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrepreneur or Business Owner"])},
      "Financial Services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial Services"])},
      "Government": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Government"])},
      "Healthcare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Healthcare"])},
      "Hospitality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hospitality"])},
      "Law": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Law"])},
      "Media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media"])},
      "Marketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketing"])},
      "Military": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Military"])},
      "Non-Profit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-Profit"])},
      "Real Estate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Real Estate"])},
      "Retired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retired"])},
      "Sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales"])},
      "Sciences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sciences"])},
      "Student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student"])},
      "Technology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technology"])},
      "Unemployed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unemployed"])},
      "Other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])}
    },
    "completeHeadline": {
      "research": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let's change the world together"])},
      "waitlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to the space for the curious!"])},
      "updates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to the space for the curious!"])},
      "investors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You’re on the list."])}
    },
    "completeCopy": {
      "research": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We'll be in touch to review your research inquiry."])},
      "waitlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for signing up. You will now be among the first to hear more about the Virgin Galactic experience and future availability of spaceflight ticket sales."])},
      "updates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for signing up. You’ll now be among the first to hear more about the Virgin Galactic experience and other updates."])},
      "investors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We'll be in touch with quarterly earnings information."])}
    }
  },
  "cardDetails": {
    "expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expand"])},
    "collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collapse"])}
  },
  "faqCategory": {
    "Spaceflight Reservations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spaceflight Reservations"])},
    "Careers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Careers"])},
    "Pre-Flight Readiness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-Flight Readiness"])},
    "Press": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Press"])},
    "Investor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investor"])}
  },
  "error": {
    "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You're lost in space"])},
    "500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A server error occurred"])}
  }
},"fr":{
  
},"es":{
  
},"it":{
  
},"de":{
  
},}),})
  nuxtI18nOptions.vueI18n = await vueI18nOptionsLoader(context)
  nuxtI18nOptions.locales = ["en","fr","es","it","de"]
  nuxtI18nOptions.defaultLocale = "en"
  nuxtI18nOptions.defaultDirection = "ltr"
  nuxtI18nOptions.routesNameSeparator = "___"
  nuxtI18nOptions.trailingSlash = false
  nuxtI18nOptions.defaultLocaleRouteNameSuffix = "default"
  nuxtI18nOptions.strategy = "prefix_except_default"
  nuxtI18nOptions.lazy = false
  nuxtI18nOptions.langDir = null
  nuxtI18nOptions.rootRedirect = null
  nuxtI18nOptions.detectBrowserLanguage = Object({"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true})
  nuxtI18nOptions.differentDomains = false
  nuxtI18nOptions.baseUrl = ""
  nuxtI18nOptions.dynamicRouteParams = false
  nuxtI18nOptions.customRoutes = "page"
  nuxtI18nOptions.pages = Object({})
  nuxtI18nOptions.skipSettingLocaleOnNavigate = false
  nuxtI18nOptions.onBeforeLanguageSwitch = (() => "")
  nuxtI18nOptions.onLanguageSwitched = (() => null)
  nuxtI18nOptions.types = undefined
  nuxtI18nOptions.debug = false
  return nuxtI18nOptions
}

export const nuxtI18nOptionsDefault = Object({vueI18n: undefined,locales: [],defaultLocale: "",defaultDirection: "ltr",routesNameSeparator: "___",trailingSlash: false,defaultLocaleRouteNameSuffix: "default",strategy: "prefix_except_default",lazy: false,langDir: null,rootRedirect: null,detectBrowserLanguage: Object({"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true}),differentDomains: false,baseUrl: "",dynamicRouteParams: false,customRoutes: "page",pages: Object({}),skipSettingLocaleOnNavigate: false,onBeforeLanguageSwitch: (() => ""),onLanguageSwitched: (() => null),types: undefined,debug: false})

export const nuxtI18nInternalOptions = Object({__normalizedLocales: [Object({"code":"en","iso":"en"}),Object({"code":"fr","iso":"fr"}),Object({"code":"es","iso":"es"}),Object({"code":"it","iso":"it"}),Object({"code":"de","iso":"de"})]})
export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
